import { of as observableOf,  Observable, of, NEVER as never } from 'rxjs';
import { Injectable } from '@angular/core';
import { switchMap, map, withLatestFrom, catchError, tap } from 'rxjs/operators';

import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as fromProfile from './index';

import { FunzMyApiService } from './../../services/funz-my-api.service';
import { Storage } from '@ionic/storage';
import { IAppState } from '../app.reducers';
import * as fromCore from '../core';
import { TrackingService } from '../../services/tracking.service';
import {
  GetProfileSubscriptionError,
  UpdatePhoneFailed,
  UpdatePhoneSuccess
} from './index';

@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private storage: Storage,
    private store: Store<IAppState>,
    private funzMyApiService: FunzMyApiService,
    private trackingService: TrackingService
  ) {}


  /**
   * UPDATE_PROFILE
   */
  @Effect()
  getProfileCredits$: Observable<any> = this.actions$
    .pipe(
      ofType(fromProfile.ProfileActionTypes.GET_PROFILE_CREDITS),
      switchMap(action => this.funzMyApiService.request('', 'credits')),
      map(data => {
        return new fromProfile.GetProfileCreditsSuccess(data);
      }),
      catchError(err => {
        return of(new fromCore.HandleError(err));
      })
    );

  @Effect()
  getProfileInvites$: Observable<any> = this.actions$
    .pipe(
      ofType(fromProfile.ProfileActionTypes.GET_PROFILE_INVITES),
      switchMap(action => this.funzMyApiService.request('', 'invites')),
      map(data => {
        return new fromProfile.GetProfileInvitesSuccess(data);
      }),
      catchError(err => {
        return of(new fromCore.HandleError(err));
      })
    );

  @Effect()
  getProfileLoyaltyPoints$: Observable<any> = this.actions$
    .pipe(
      ofType(fromProfile.ProfileActionTypes.GET_PROFILE_LOYALTY_POINTS),
      switchMap(action => this.funzMyApiService.request('', 'loyalty_points')),
      map(data => {
        return new fromProfile.GetProfileLoyaltyPointsSuccess(data);
      }),
      catchError(err => {
        return of(new fromCore.HandleError(err));
      })
    );

  @Effect()
  updatePhone: Observable<any> = this.actions$
    .pipe(
      ofType(fromProfile.ProfileActionTypes.UPDATE_PHONE),
      map((action: fromProfile.UpdatePhone) => action.payload),
      switchMap((phone: string) =>
        this.funzMyApiService.updateUserPhone(phone).pipe(
          map((res) => res.success ? new UpdatePhoneSuccess() : new UpdatePhoneFailed()),
          catchError(() => observableOf(new UpdatePhoneFailed()))
        )
      )
    );

  @Effect({ dispatch: false })
  updateDevice: Observable<any> = this.actions$
    .pipe(
      ofType(fromProfile.ProfileActionTypes.UPDATE_DEVICE),
      map((action: fromProfile.UpdateDevice) => action.payload),
      switchMap((data: any) => this.funzMyApiService.updateDevice(data))
    );

  @Effect()
  updateCity$: Observable<Action> = this.actions$
    .pipe(
      ofType(fromProfile.ProfileActionTypes.UPDATE_CITY),
      map((action: fromProfile.UpdateProfile) => action.payload),
      withLatestFrom(this.store.select(fromProfile.getProfile)),
      map(([cityId, profile]) => {
        const data = {
          ...profile,
          city_id: cityId
        };
        return new fromProfile.UpdateProfile({ ...data });
      })
    );

  @Effect({ dispatch: false })
  update$: Observable<Action> = this.actions$
    .pipe(
      ofType(fromProfile.ProfileActionTypes.UPDATE),
      withLatestFrom(this.store.select(fromProfile.getProfile)),
      tap((args: any) => {
        const [actionType, profileData] = args;
        if (!!profileData.user_id) {
          this.trackingService.pushToDataLayer({
            UserID: profileData.user_id
          });
        }
      })
    );

  @Effect({ dispatch: false })
  updateSubscribedToMarketingEmails$: Observable<Action> = this.actions$
    .pipe(
      ofType(fromProfile.ProfileActionTypes.TOGGLE_MARKETING_OPT_IN),
      withLatestFrom(
        this.store.select(fromProfile.getProfileSubscribedToMarketingEmails)
      ),
      switchMap(([id, result]) => {
        return this.funzMyApiService.updateUserSubscribedToMarketingEmails(
          result
        );
      })
    );

  @Effect()
  updateProfileForm$: Observable<any> = this.actions$
    .pipe(
      ofType(fromProfile.ProfileActionTypes.UPDATE_PROFILE_FORM),
      map((action: fromProfile.UpdateProfile) => action.payload),
      withLatestFrom(this.store.select(fromCore.getCityOnBoarding)),
      switchMap(([data, cityOnBoarding], index) => {
        const { user_id, city_id } = data;
        if (city_id && cityOnBoarding) {
          this.store.dispatch(new fromCore.SetLastSelectedCityId(city_id));
        }
        return this.funzMyApiService.UpdateProfile(user_id, data, 'profiles').pipe(
          map(returnData => new fromProfile.UpdateProfileSuccess(returnData)),
          catchError(error => {
            return of(new fromProfile.UpdateProfileFailure(error.error));
          }), );
      })
    );

  @Effect()
  updateProfile$: Observable<any> = this.actions$
    .pipe(
      ofType(fromProfile.ProfileActionTypes.UPDATE_PROFILE),
      map((action: fromProfile.UpdateProfile) => action.payload),
      withLatestFrom(this.store.select(fromCore.getCityOnBoarding)),
      switchMap(([data, cityOnBoarding], index) => {
        const { user_id, city_id } = data;
        if (city_id && cityOnBoarding) {
          return of(new fromCore.SetLastSelectedCityId(city_id));
        } else {
          return [];
        }
      })
    );

  @Effect()
  getSubscription$: Observable<any> = this.actions$
    .pipe(
      ofType(fromProfile.ProfileActionTypes.GET_PROFILE_SUBSCRIPTION),
      switchMap(() => {
        return this.funzMyApiService.fetchSubscription().pipe(
          map((subscription) => new fromProfile.GetProfileSubscriptionSuccess(subscription)),
          catchError((err) => observableOf(new GetProfileSubscriptionError()))
        );
      })
    );
}
