import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ENV } from '../../../environments/environment';
import { AppApi } from '../../core/api/app.api';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import * as fromCore from '../store/core';
import { IAppState } from '../store/app.reducers';
import { Store } from '@ngrx/store';
import { TrackingService, FB_COMPLETE_REGISTRATION, ADAPTIVE_NEW_REGISTRATION } from './tracking.service';
import { Storage } from '@ionic/storage';
import { SearchProxy } from '../../shared-stores/search/search.proxy';
import { OneSignalService } from './onesignal.service';
import { BehaviorSubject } from 'rxjs';
import { take, takeWhile, tap } from 'rxjs/operators';
import { get, has } from 'lodash';
import { browserCheck, cleanObject } from './funz.utils';
import { Device } from '@ionic-native/device/ngx';

const [browser, browserVersion] = browserCheck();

declare var window;

interface IUserStatus {
  total_loyalty_points: number;
  points_message: string;
  total_credits: string;
  unread_conversations_count: number;
  unread_notifications_count: number;
  city_id: number;
}

export interface IUserData {
  user_id: number | any;
  city_id: number | any;
  name: string;
  email: string;
  phone: string;
  birthday: Date;
  avatar_url: string;
  subscribed_to_marketing_emails: boolean;
  total_loyalty_points: number;
  onesignal_paying: boolean;
  top_category: string;
  points_message: string;
  total_credits: string;
  new_user?: string;
  needs_to_fb_onboard?: boolean;
  has_active_subscriptions: boolean;
  provider: string;
  sign_in_count: number;
  is_seller: boolean;
  is_affiliate: boolean;
  pusher_chat_id: string;
}

interface IProfileBodyResponse {
  support_phone: string;
  user_status?: IUserStatus;
  user_data?: IUserData;
}

export const OneSignalTagsSentKey = 'one_signal_tags_sent';

@Injectable()
export class ResponseHandlerService {

  baseDomain: string;
  domain: string;
  windowObj = window as any;

  oneSignalInitialized = false;
  oneSignalScriptLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  onesignalTagsData: any = {};

  constructor(
    private appApi: AppApi,
    private oneSignal: OneSignal,
    private store: Store<IAppState>,
    private trackingService: TrackingService,
    private storage: Storage,
    private device: Device
  ) {
    this.store.select(fromCore.getBaseDomain).subscribe(baseDomain => {
      this.baseDomain = baseDomain;
    });
    this.store.select(fromCore.getDomain).subscribe(domain => {
      this.domain = domain;
    });
    this.sendOneSignalTagsIfNeverSent();
  }

  handleHeaders(headers: HttpHeaders) {
    if (headers.get('access-token')) {
      const newHeaders = {
        accessToken: headers.get('access-token'),
        client: headers.get('client'),
        expiry: headers.get('expiry'),
        tokenType: headers.get('token-type'),
        uid: headers.get('uid')
      };
      this.appApi.setHeaders(newHeaders);
    }
  }

  // TODO: Change 'IProfileBodyResponse' to 'any'?
  handleProfileUpdate(body: IProfileBodyResponse) {

    if (body && body.hasOwnProperty('user_status')) {
      const {
        total_loyalty_points,
        total_credits,
        points_message,
        unread_notifications_count,
        unread_conversations_count
      } = body.user_status;
      this.appApi.updateProfile({
        total_loyalty_points,
        points_message,
        total_credits,
        unread_notifications_count,
        unread_conversations_count
      });
    }

    if (body && body.hasOwnProperty('user_data')) {
      // OLD PWA
      const support_phone = get(body, 'user_data.site.support_phone');
      const site_id = get(body, 'user_data.site.site_id');
      const {
        name,
        email,
        avatar_url,
        total_loyalty_points,
        points_message,
        total_credits,
        birthday,
        onesignal_paying,
        subscribed_to_marketing_emails,
        top_category,
        phone,
        user_id,
        city_id,
        new_user,
        needs_to_fb_onboard,
        has_active_subscriptions,
        provider,
        sign_in_count,
        is_seller,
        is_affiliate,
        pusher_chat_id // OLD PWA
      } = body.user_data;

      // take care of onesignal when user is logged in
      this.onesignalTagsData = {
        user_id,
        onesignal_paying,
        top_category,
        site_id,
        city_id
      };

      if (!this.windowObj.cordova) {
        this.initOneSignalSendListener();
      }

      if (window.cordova && (ENV.mode === 'Prod')) {
       window.plugins.appsFlyer.setAppUserId(user_id.toString());
        const eventName = new_user ? 'af_complete_registration' : 'af_login';
        const eventValues = { af_customer_user_id: user_id.toString() };
        window.plugins.appsFlyer.trackEvent(eventName, eventValues);
      }

      this.appApi.updateProfile({
        support_phone,
        name,
        email,
        avatar_url,
        total_loyalty_points,
        points_message,
        total_credits,
        onesignal_paying,
        subscribed_to_marketing_emails,
        top_category,
        birthday,
        phone,
        user_id,
        city_id,
        has_active_subscriptions,
        provider,
        sign_in_count,
        is_seller,
        is_affiliate,
        needs_to_fb_onboard,
      });
      if (new_user) {
        this.trackingService.trackGAEvent(ADAPTIVE_NEW_REGISTRATION);
        if (provider === 'facebook') {
          this.trackingService.trackGAEvent(FB_COMPLETE_REGISTRATION);
        }
      }
    }
  }

  initOneSignalSendListener() {
    this.oneSignalScriptLoaded$.pipe(tap((loaded) => {
      if (loaded && this.oneSignalInitialized && location.href.includes('https')) {
        const OneSignal = window.OneSignal;
        OneSignal.sendTags(cleanObject(this.onesignalTagsData));
        OneSignal.getUserId().then(playerId => {
          this.store.select(fromCore.getSiteHeadersLocationSuffix).pipe(take(1)).subscribe(siteSuffix => {
            if (!!playerId) {
              const updateData = cleanObject({
                player_id: playerId,
                platform_type: get(OneSignal, 'browser.chrome', browser === 'Chrome') ? 5 : 7, // chrome or safari
                model: get(OneSignal, 'browser.name', browser),
                user_id: this.onesignalTagsData.user_id,
                site_id: this.onesignalTagsData.site_id,
                city_id: this.onesignalTagsData.city_id,
                source_app: !!siteSuffix ? `web_${siteSuffix}` : null
              });
              localStorage.setItem(OneSignalTagsSentKey, 'true');
              this.appApi.updateDevice(updateData);
            }
          })
        });
      }
    }), takeWhile(loaded => {
      return !loaded;
    })).subscribe();
  }

  handleUserStatus(userStatus: IUserStatus) {
    this.appApi.updateProfile(userStatus);
  }

  handleError(response: HttpErrorResponse, url: string) {
    if (
      response.status === 401 &&
      url.includes(this.baseDomain) &&
      !url.includes('sign_in')
    ) {
      this.appApi.requireAuth();
    }
  }

  onesignalNotificationInit(siteCode) {
    // for debugging
    // OneSignal.log.setLevel('trace');
    this.windowObj.OneSignal.push(['init', {
      appId: ENV.onesignal[siteCode].chrome_id,
      safari_web_id: ENV.onesignal[siteCode].safari_id,
      autoRegister: true,
      welcomeNotification: {
       disable: true
      }
    }]);
    this.oneSignalInitialized = true;
  }

  loadOnesignalScript() {
    if (document.getElementById('onesignal-script')) {
      this.oneSignalScriptLoaded$.next(true);
    } else {
      const OnesignalSDKJS = document.createElement('script');
      OnesignalSDKJS.id = 'onesignal-script';
      OnesignalSDKJS.async = true;
      OnesignalSDKJS.src = 'https://cdn.onesignal.com/sdks/OneSignalSDK.js';
      OnesignalSDKJS.onload = () => {
        const oneSignalLoad = this.storage.get('domain').then(domain => {
          if (domain) {
            const site_code = domain.split('.')[0];
            try {
              if (has(window, 'safari.pushNotification')) {

                window.safari.pushNotification.requestPermission(
                  'https://cdn.onesignal.com',
                  ENV.onesignal[site_code].safari_id,
                  { app_id: ENV.onesignal[site_code].chrome_id },
                  (response) => {
                    this.onesignalNotificationInit(site_code);
                    this.oneSignalScriptLoaded$.next(true);
                  }
                );
              } else if (has(window, 'Notification.requestPermission')) {
                window.Notification.requestPermission().then((permission) => {
                  this.onesignalNotificationInit(site_code);
                  this.oneSignalScriptLoaded$.next(true);
                });
              }
            } catch (err) {
              console.log('Catch err', err);
            }
          }

        });
      };
      document.head.appendChild(OnesignalSDKJS);
    }
  }

  sendOneSignalTagsIfNeverSent() {
    if (!(window as any).cordova) {
      const localStorage = window.localStorage;
      if (!localStorage.getItem(OneSignalTagsSentKey)) {
        this.initOneSignalSendListener();
      } else {
        this.initializeOneSignalJs();
        this.initOneSignalSendListener();
      }
    }
  }


  initializeOneSignalJs() {
    if (this.oneSignalInitialized) {
      return;
    }
    if (!window.cordova && location.href.includes('https')) {
      this.loadOnesignalScript();
    }
  }
}
