import { ENV } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { AppApi } from '../api/app.api';
import { WindowRef } from './window.ref';
import { Device } from '@ionic-native/device/ngx';
import { NavController } from '@ionic/angular';
import { TrackingService } from './tracking.service';
import { get, pick } from 'lodash';
import { Store } from '@ngrx/store';
import * as fromCore from '../store/core';
import * as fromApp from '../../core/store/app.reducers';
import * as fromProfile from '../../core/store/profile';
import { combineLatest, interval, race } from 'rxjs';
import { takeWhile, skip, mergeMap } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { CategoriesProxy } from '../store/categories/categories.proxy';

@Injectable({ providedIn: 'root' })
export class OneSignalService {
  currentSiteId: number = null;
  currentSignedIn: boolean = null;
  currentCityId: number = null;
  oneSignalLoaded = false;

  constructor(
    private oneSignal: OneSignal,
    private appApi: AppApi,
    private winRef: WindowRef,
    private device: Device,
    private navCtrl: NavController,
    private store: Store<fromApp.IAppState>,
    private trackingService: TrackingService,
    private storage: Storage,
    private categoriesProxy: CategoriesProxy,
  ) {
  }

  loadOneSignal(approved = false) {
    if (this.oneSignalLoaded) {
      return;
    }
    if (approved) {
      this.initOneSignal();
    } else {
      this.storage.get('canLoadOneSignal').then(canLoad => {
        if (canLoad) {
          this.initOneSignal();
        }
      });
    }
  }

  initOneSignal() {
    this.oneSignal.startInit(
      ENV.one_signal_app_id,
      ENV.one_signal_google_project_number
    );
    if (ENV.mode === 'Prod' || ENV.mode === 'XYZ') {
      this.oneSignal.inFocusDisplaying(
        this.oneSignal.OSInFocusDisplayOption.Notification
      );
    } else {
      this.oneSignal.inFocusDisplaying(
        this.oneSignal.OSInFocusDisplayOption.InAppAlert
      );
    }
    this.oneSignal.handleNotificationOpened().subscribe(jsonData => {
      console.log('handleNotificationOpened', jsonData)
      this.winRef.nativeWindow.plugins.appsFlyer.trackEvent('af_opened_from_push_notification');
      this.notificationHandler(jsonData.notification.payload.additionalData);
    });
    this.oneSignal.endInit();

    this.oneSignalLoaded = true;

    this.runUpdateListener();
  }

  runUpdateListener() {
    combineLatest([
      this.store.select(fromCore.getLastSelectedCityId),
      this.store.select(fromCore.getIsSignedIn),
      this.store.select(fromCore.getSiteId)
    ]).subscribe(([cityId, signedIn, siteId]) => {
      if (
        signedIn !== null && (
          this.currentCityId !== cityId ||
          this.currentSignedIn !== signedIn ||
          this.currentSiteId !== siteId
        )) {
        this.currentCityId = cityId;
        this.currentSignedIn = signedIn;
        this.currentSiteId = siteId;

        this.updateUserDevice(siteId, cityId, signedIn);
      }
    });
  }

   updateUserDevice(siteId, cityId, signedIn) {
    const baseParams = { site_id: siteId, city_id: cityId };
    if (!signedIn) {
      this.registerPlayerId(baseParams);
    } else {
      this.store.select(fromProfile.getOneSignalData).pipe(skip(1), takeWhile(data => !!data.user_id)).subscribe(userData => {
        this.registerPlayerId({ ...baseParams, ...userData });
      });
    }
  }

  registerPlayerId(oneSignalData: any = {}) {
    this.oneSignal.sendTags(oneSignalData);
    race(
      interval(500).pipe(
        mergeMap(() => this.oneSignal.getPermissionSubscriptionState()),
        takeWhile(data => !data.subscriptionStatus.userId, true),
      )
    ).subscribe((data: any) => {
      if (!data.subscriptionStatus.userId) {
        return;
      }
      const updateDeviceData = pick({
        player_id: data.subscriptionStatus.userId,
        platform_type: this.device.platform === 'Android' ? 1 : 0,
        model: this.device.model,
        uuid: this.device.uuid,
        ...oneSignalData,
      }, ['player_id', 'platform_type', 'model', 'uuid', 'user_id', 'city_id', 'site_id']);

      this.trackingService.updateOneSignalPlayerId(updateDeviceData.player_id);

      this.appApi.updateDevice(updateDeviceData);
    });
  }

  notificationHandler(additionalData) {
    this.categoriesProxy.categoriesIsFetched().then((catData) => {
      const marketingData = additionalData.marketingData;
      this.appApi.handleMarketingParams(marketingData);
      this.navCtrl.navigateForward(additionalData.route_url);
      this.trackingService.traceAction('push_notification', { route_url: additionalData.route_url });
    });
  }
}
