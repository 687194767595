import { take, map, switchMap, concatMap, catchError, mergeMap, withLatestFrom, tap, delayWhen } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { zip, of, from } from 'rxjs';
import * as CoreActions from './core.actions';
import * as ProfileActions from '../profile';
import * as fromCities from './../../store/cities';
import { AuthHeaders } from '../../models/authHeaders.model';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/app.reducers';
import { AuthorizationService } from '../../services/authorization.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { APP_PAGES } from '../../../pages';
import 'rxjs/operators/map';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FunzApiService } from '../../services/funz-api.service';
import { SiteHeaders } from '../../models/siteHeaders.model';
import * as CoreStore from './index';
import { AppApi } from '../../api/app.api';
import { uniq, keys, set, reduce } from 'lodash';
import { SeoService } from '../../services/seo.service';
import { ENV } from '../../../../environments/environment';
import { PAGE_TO_URL_TRANSFORMER } from '../../../app-routing.utils';
import { Router } from '@angular/router';
import { TrackingService } from '../../services/tracking.service';
import { CookieService } from 'ngx-cookie-service';
import { ParamsService } from '../../services/params.service';
import { GeneralHelperService } from '../../services/general-helper.service';
import { ErrorTrackerService } from '../../services/error-tracker.service';
import { PwaApiService } from '../../services/pwa-api.service';
import * as LogRocket from 'logrocket';

export const IonicStorageKeyMap = {
  'access-token': 'accessToken',
  client: 'client',
  expiry: 'expiry',
  'token-type': 'tokenType',
  uid: 'uid'
};
const IonicStorageKeyArray = keys(IonicStorageKeyMap);
const getDataValue = (key: string, data: AuthHeaders | null) =>
  data ? data[IonicStorageKeyMap[key]] : null;
const isAuthHeaderValid = (header: AuthHeaders) =>
  header.accessToken && header.client && header.expiry && header.uid;
const isSiteHeaderValid = (header: SiteHeaders) =>
  header.domain && header.subdomain && header.locales && header.currency_code && header.support_email
  && header.support_phone && header.direction && header.mobile_hp_image && header.mobile_video && header.mobile_preview_image
  && header.social_usernames && header.hasOwnProperty('city_on_boarding') && header.cities
  && header.site_id && header.api_domain && header.hasOwnProperty('default_marketing_opt_in');

const toAuthHeadersJson = (
  accessToken,
  client,
  expiry,
  tokenType,
  uid,
  lastSelectedCity,
  isGelocationPrompted
) => ({
  accessToken,
  client,
  expiry,
  tokenType,
  uid,
  lastSelectedCity,
  isGelocationPrompted
});

const toMarketingParamsJson = (
  originalUtmMedium,
  originalUtmSource,
  originalUtmTerm,
  originalUtmContent,
  originalUtmCampaign,
  originalExpiresAt,
  originalAdId,
  expiresAt,
  utmExpiresAt,
  utmSource,
  utmMedium,
  utmCampaign,
  utmContent,
  utmTerm,
  adId,
  landingUrl,
  referringUrl,
  marketingChannel,
  referer,
  afBannerName
) => ({
  original_utm_medium: originalUtmMedium,
  original_utm_source: originalUtmSource,
  original_utm_term: originalUtmTerm,
  original_utm_content: originalUtmContent,
  original_utm_campaign: originalUtmCampaign,
  original_expires_at: originalExpiresAt,
  original_ad_id: originalAdId,
  expires_at: expiresAt,
  utm_expires_at: utmExpiresAt,
  utm_source: utmSource,
  utm_medium: utmMedium,
  utm_campaign: utmCampaign,
  utm_content: utmContent,
  utm_term: utmTerm,
  ad_id: adId,
  landing_url: landingUrl,
  referring_url: referringUrl,
  marketing_channel: marketingChannel,
  referer,
  af_banner_name: afBannerName,
});

const toSiteHeadersJson = (
  domain,
  subdomain,
  locales,
  supportEmail,
  supportPhone,
  direction,
  currencyCode,
  mobileHpImage,
  mobileVideo,
  mobilePreviewImage,
  socialUsernames,
  cityOnBoarding,
  cities,
  siteId,
  apiDomain,
  defaultMarketingOptIn,
) => ({
  domain,
  subdomain,
  locales,
  support_email: supportEmail,
  support_phone: supportPhone,
  direction,
  currency_code: currencyCode,
  mobile_hp_image: mobileHpImage,
  mobile_video: mobileVideo,
  mobile_preview_image: mobilePreviewImage,
  social_usernames: socialUsernames,
  city_on_boarding: cityOnBoarding,
  cities,
  site_id: siteId,
  api_domain: apiDomain,
  default_marketing_opt_in: defaultMarketingOptIn,
});

const paths = {
  funz: APP_PAGES.FunzPage,
  category: APP_PAGES.CategoryPage,
  discover: APP_PAGES.DiscoverPage
};

@Injectable()
export class CoreEffects {
  cordova = !!(window as any).cordova;

  @Effect({ dispatch: false })
  firstTimeCheck = this.actions$.pipe(
    ofType(CoreActions.CHECK_FIRST_TIME),
    switchMap((action: CoreActions.CheckFirstTime) => {
      LogRocket.log('begin CheckFirstTime');
      return this.storage.get('has-been-run');
    } ),
    map(hasBeenRun => {
      if (!hasBeenRun) {
        LogRocket.log('its first time, setting local storage');
        this.storage.set('has-been-run', true).catch((error: Error) => this.ghs.logStorageError(error));
        return this.store.dispatch(new CoreActions.SetFirstTime());
      }
      LogRocket.log('its not first time');
      return [];
    })
  );

  // TODO: use only for pwa. currently not called for app
  @Effect()
  siteHeadersLoad = this.actions$
    .pipe(
      ofType(CoreActions.ActionTypes.LOAD_SITE_HEADERS),
      switchMap((action: CoreActions.LoadSiteHeaders) =>
        zip(
          this.storage.get('domain'),
          this.storage.get('subdomain'),
          this.storage.get('locales'),
          this.storage.get('support_email'),
          this.storage.get('support_phone'),
          this.storage.get('direction'),
          this.storage.get('currency_code'),
          this.storage.get('mobile_hp_image'),
          this.storage.get('mobile_video'),
          this.storage.get('mobile_preview_image'),
          this.storage.get('social_usernames'),
          this.storage.get('city_on_boarding'),
          this.storage.get('cities'),
          this.storage.get('site_id'),
          this.storage.get('api_domain'),
          this.storage.get('default_marketing_opt_in'),
          toSiteHeadersJson
        )
      ),
      withLatestFrom(from(this.appApi.isLocalStorageExpired('site'))),
      mergeMap((args: any) => {
        const [ siteHeaders, localStorageExpired ] = args;
        let returnedActions: any = [
          isSiteHeaderValid(siteHeaders)
            ? new CoreActions.SetSiteHeaders({ ...siteHeaders })
            : new CoreActions.FetchSiteHeaders()];
        if (localStorageExpired && isSiteHeaderValid(siteHeaders)) {
          returnedActions = uniq([...returnedActions, new CoreActions.FetchSiteHeaders()]);
        }
        return returnedActions;
      })
    );

  @Effect()
  siteHeadersFetch = this.actions$
    .pipe(
      ofType(CoreActions.ActionTypes.FETCH_SITE_HEADERS),
      map(() => {
        if (this.cordova || location.hostname.includes('m.funzing') || location.hostname === 'localhost') {
          const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          return { timezone };
        } else {
          const domain = location.hostname.replace('m.', '');
          const subdomain = domain.split('.')[0];
          return { subdomain, domain };
        }
      }),
      switchMap((data: any) => {
        // TODO IMPROVE TO BEST SOLUTION
        const { subdomain, domain, timezone } = data;
        // if ((subdomain == 'il' || subdomain == 'uk') && ENV.cached_json_external_url) {
        //   console.log('WORKS IF', domain)
          // return this.pwaApiService.requestSiteHeaders(subdomain);
        // } else {
        //   console.log('WORKS Else', domain)
          // return this.funzApi.requestSite(domain && ENV.mode == 'Prod' ? `&domain=${domain}` : `&timezone=${timezone}`);
        // }
        if (subdomain === 'il') {
          return this.funzApi.requestSite(domain && ENV.mode === 'Prod' ? `&domain=${domain}` : `&timezone=Asia/Jerusalem`);
        } else {
          return this.funzApi.requestSite(domain && ENV.mode === 'Prod' ? `&domain=${domain}` : `&timezone=${timezone}`);
        }
      }),
      mergeMap((siteHeaders) => {
        this.appApi.setLocalStorageExpiration('site', 1000 * 60 * 60);
        return [new CoreActions.SetSiteHeaders({ ...siteHeaders })];
      })
    );

  @Effect()
  siteHeadersSet = this.actions$
    .pipe(
      ofType(CoreActions.ActionTypes.SET_SITE_HEADERS),
      map((action: CoreActions.SetSiteHeaders) => action.payload),
      mergeMap(siteData => {
        if (!this.cordova) {
          keys(siteData).forEach(key => {
            this.storage.set(key, siteData[key]);
          });
        }
        this.seoService.setRootCanonicalURL();
        return [new CoreActions.SetDefaultMarketingOptIn(
          siteData.default_marketing_opt_in
        ),
          new fromCities.FetchCityListSuccess({ cities: siteData.cities })];
      })
    );

  // TODO: Remove?
  // TODO: test for pwa
  // @Effect()
  // siteHeadersSave = this.actions$
  //   .pipe(
  //     ofType(CoreActions.SAVE_SITE_HEADERS),
  //     map((action: CoreActions.SaveSiteHeaders) => action.payload),
  //     delayWhen((siteData) => {
  //       if (!this.cordova) {
  //         const promises = [];
  //         keys(siteData).forEach( (key) => promises.push(this.storage.set(key, siteData[key])));
  //         return from(Promise.all(promises));
  //       }
  //     }),
  //     map(() => new CoreActions.SiteHeadersSaved())
  //   );

  @Effect()
  headersFetch = this.actions$.pipe(
    ofType(CoreActions.FETCH_HEADERS),
    switchMap((action: CoreActions.FetchHeaders) => {
      LogRocket.log('begin FetchHeaders');
      return zip(
        this.storage.get('access-token'),
        this.storage.get('client'),
        this.storage.get('expiry'),
        this.storage.get('token-type'),
        this.storage.get('uid'),
        this.storage.get('lastSelectedCity'),
        this.storage.get('isGelocationPrompted'),
        toAuthHeadersJson
      );
    }),
    mergeMap((authHeaders: any) => {
      const lastSelectedCityId = authHeaders.lastSelectedCity;
      const lastSelectedCity = lastSelectedCityId || null;
      const isGelocationPrompted = authHeaders.isGelocationPrompted || false;
      delete authHeaders.lastSelectedCity;
      delete authHeaders.isGelocationPrompted;
      const returnedActions: any = [
        new CoreActions.SetLastSelectedCityId(lastSelectedCityId),
        new fromCities.LoadSelectedCity(lastSelectedCity),
        new CoreActions.SetIsGeolocationPrompted(isGelocationPrompted)
      ];
      if (isAuthHeaderValid(authHeaders)) {
        LogRocket.log('valid headers');
        returnedActions.push(new CoreActions.LoadHeaders(authHeaders));
      } else {
        LogRocket.log('invalid headers');
        returnedActions.push(new CoreActions.AuthInvalid());
        returnedActions.push(new ProfileActions.ResetProfile());
      }
      return returnedActions;
    })
  );

  @Effect()
  headersLoad = this.actions$
    .pipe(
      ofType(CoreActions.LOAD_HEADERS),
      switchMap((action: CoreActions.LoadHeaders) =>
        this.auth.validateToken(this.auth.toParams(action.payload))
      ),
      mergeMap((response) => [
          new CoreActions.BootstrapAuthValid()
        ]
      ),
      catchError(error => [])
    );

  @Effect()
  headersSet = this.actions$
    .pipe(
      ofType(CoreActions.SET_HEADERS),
      map((action: CoreActions.SetHeaders) => action.payload),
      delayWhen((authData: AuthHeaders) => from(this.setStorage(authData))),
      map(results => new CoreActions.AuthHeadersSaved())
    );

  @Effect()
  authInvalid = this.actions$
    .pipe(
      ofType(CoreActions.AUTH_INVALID),
      switchMap(() => zip(this.clearAuthHeadersFromStorage())),
      map(headers => ({ type: 'AUTH_INVALID_END', payload: headers }))
    );

  @Effect()
  authConfirmation$ = this.actions$
    .pipe(
      ofType(CoreActions.ActionTypes.OPEN_AUTH_CONFIRM_DIALOG),
      switchMap(headers => this.getAuthConfirmTexts()),
      concatMap(async text => {
        const alert = await this.alertCtrl.create({
          header: text['store.core.auth_confirm.title'],
          message: text['store.core.auth_confirm.subtitle'],
          backdropDismiss: false,
          buttons: [
            {
              text: text['store.core.auth_confirm.cancel'],
              role: 'cancel',
              handler: () => {
                this.store.dispatch(
                  new CoreActions.SetRootPage(APP_PAGES.DiscoverPage)
                );
              }
            },
            {
              text: text['store.core.auth_confirm.ok'],
              handler: () => {
                this.store.dispatch(new CoreActions.AuthInvalid({ userInitiated: true }));
              }
            }
          ]
        });
        alert.present();
        return [];
      })
    );

  @Effect()
  checkInstallDeeplink = this.actions$
    .pipe(
      ofType(CoreActions.CHECK_INSTALL_DEEPLINK),
      map((action: CoreActions.CheckInstallDeeplink) => action.payload),
      map((installData) => {
        const parsedResult = JSON.parse(installData);
        let firstLaunch = false;
        if (parsedResult.data && parsedResult.data.is_first_launch) {
          firstLaunch = /^(true|1)$/i.test(parsedResult.data.is_first_launch);
        }
        if (
          firstLaunch &&
          parsedResult.data &&
          parsedResult.data.af_dp
        ) {
          this.appsFlyerDeepLinkHandler(parsedResult.data.af_dp);
          this.appApi.handleMarketingParams(parsedResult.data);
          this.trackingService.traceAction('deeplink_first_installation', parsedResult.data);
        }
        this.trackingService.traceAction('appsflyer_first_installation', parsedResult.data);
        return new CoreActions.ResetFirstTime();
      })
    );

    // MAYBE NEED TO REMOVE
  @Effect({ dispatch: false })
  saveMarketingParams = this.actions$
    .pipe(
      ofType(CoreActions.SAVE_MARKETING_PARAMS),
      map((action: CoreActions.SaveMarketingParams) => action.payload),
      map((marketingParams) => {
        if (marketingParams) {
          this.appApi.setLocalStorageExpiration('marketingParams', 28);
        }
      })
    );

  @Effect({ dispatch: false })
  fetchMarketingParams = this.actions$
    .pipe(
      ofType(CoreActions.LOAD_MARKETING_PARAMS),
      switchMap((action: CoreActions.LoadMarketingParams) => {
        if (this.cordova) {
          return zip(
            this.storage.get('original_utm_medium'),
            this.storage.get('original_utm_source'),
            this.storage.get('original_utm_term'),
            this.storage.get('original_utm_content'),
            this.storage.get('original_utm_campaign'),
            this.storage.get('original_expires_at'),
            this.storage.get('original_ad_id'),
            this.storage.get('utm_expires_at'),
            this.storage.get('utm_expires_at'),
            this.storage.get('utm_source'),
            this.storage.get('utm_medium'),
            this.storage.get('utm_campaign'),
            this.storage.get('utm_content'),
            this.storage.get('utm_term'),
            this.storage.get('ad_id'),
            this.storage.get('landing_url'),
            this.storage.get('referring_url'),
            this.storage.get('marketing_channel'),
            this.storage.get('referer'),
            this.storage.get('af_banner_name'),
            toMarketingParamsJson
          );
        } else {
          const maincookiesObj = {};
          const mainWantedCookies = [
            'original_utm_medium',
            'original_utm_source',
            'original_utm_term',
            'original_utm_content',
            'original_utm_campaign',
            'original_ad_id',
            'original_expires_at',
            'utm_medium',
            'utm_source',
            'utm_term',
            'utm_content',
            'utm_campaign',
            'utm_expires_at',
            'ad_id',
          ];

          mainWantedCookies.forEach((cookieName) => {
            if (this.cookieService.check(cookieName) && cookieName !== 'utm_expires_at') {
              set(maincookiesObj, cookieName, this.cookieService.get(cookieName));
            }
            if (this.cookieService.check(cookieName) && cookieName === 'utm_expires_at') {
              set(maincookiesObj, 'expires_at', this.cookieService.get(cookieName));
            }
          });

          const prefixReplace = ['ad', 'utm'];
          const wantedCookies = ['utm_medium', 'utm_source', 'utm_term', 'utm_content', 'utm_campaign', 'ad_id', 'referer'];
          const cookiesObj = reduce(wantedCookies, (acc, cookieName) => {
            const cookiePrefix = cookieName.split('_').shift();
            const orderCookieName = prefixReplace.some(prefix => prefix === cookiePrefix) ? `order_${cookieName}` : cookieName;
            if (this.cookieService.check(orderCookieName)) {
              set(acc, cookieName, this.cookieService.get(orderCookieName));
            }
            return acc;
          }, {});
          return of({ ...maincookiesObj, ...cookiesObj });
        }
      }),
      map((marketingParams: any) => {
        const paramsToStore = this.trackingService.checkUtmsStorageExpiration(marketingParams);
        this.store.dispatch(new CoreActions.RestoreMarketingParams(paramsToStore));
      })
    );

  @Effect({ dispatch: false })
  checkOpenDeeplink = this.actions$
    .pipe(
      ofType(CoreActions.CHECK_OPEN_DEEPLINK),
      map((action: CoreActions.CheckOpenDeeplink) => action.payload),
      withLatestFrom(this.store.select(CoreStore.getDeeplinkString)),
      map(([data, oldDeeplinkString]) => {
        let deeplinkString;
        if (data.af_dp) {
          deeplinkString = data.af_dp;
          this.appApi.handleMarketingParams(data);
          this.trackingService.traceAction('deeplink_open', data);
        } else {
          const url = data.link;
          deeplinkString = this.getQueryVariable(url, 'af_dp');
          const marketingParams = this.paramsService.latestUrlParams(url);
          this.appApi.handleMarketingParams(marketingParams);
          this.trackingService.traceAction('deeplink_open', marketingParams);
        }
        if (deeplinkString && deeplinkString !== oldDeeplinkString) {
          const link = deeplinkString.split('funzing://app/')[1];
          this.deeplinkHandler(link);
        }
      })
    );

  // Re-add after when dealing with onesignal
  // @Effect({ dispatch: false })
  // handleDeeplink = this.actions$
  //   .pipe(
  //     ofType(CoreActions.HANDLE_DEEPLINK),
  //     map((action: CoreActions.HandleDeeplink) => action.payload),
  //     map(({ actionType, params }) => {
  //       this.deeplinkHandler(paths[actionType], { ...params });
  //     })
  //   );

  @Effect({ dispatch: false })
  handleError$ = this.actions$.pipe(
    ofType(CoreActions.HANDLE_ERROR),
    map((action: CoreActions.HandleError) => action.payload),
    tap((error) => {
      console.log('SERVER ERROR', error);
      this.errorTracker.trackError(error);
      this.navCtrl.navigateRoot('home');
    })
  );

  @Effect({ dispatch: false })
  setLastSelectedCityId$ = this.actions$.pipe(
    ofType(CoreActions.SET_LAST_SELECTED_CITY_ID),
    map((action: CoreActions.SetLastSelectedCityId) => action.payload),
    tap((lastSelectedCityId) => {
      this.storage.set('lastSelectedCity', lastSelectedCityId)
      .catch((error: Error) => this.ghs.logStorageError(error));
  })
  );


    // TODO Investigte if we need this action at all
  @Effect({ dispatch: false })
  loginSkipped$ = this.actions$
    .pipe(
      ofType(CoreActions.LOGIN_SKIPPED),
      withLatestFrom(
        this.store.select(CoreStore.getPwaDeeplinkedSlug),
        this.store.select(CoreStore.getPwaRequestedPage),
        this.store.select(CoreStore.getPwaRequestedPageParams),
        this.store.select(CoreStore.getIsSignedIn)
      ),
      map(([action, deeplinkedSlug, page, params, signedIn]) => {
        if (deeplinkedSlug) {
          this.navCtrl.navigateRoot(
            PAGE_TO_URL_TRANSFORMER.getUrlByPageName(APP_PAGES.LandingPage, { id: deeplinkedSlug })
          );
        } else if ((signedIn || isNaN(params.id)) && page) {
          this.navCtrl.navigateRoot(
            PAGE_TO_URL_TRANSFORMER.getUrlByPageName(page, params)
          );
        } else {
          this.navCtrl.navigateRoot(
            PAGE_TO_URL_TRANSFORMER.getUrlByPageName(APP_PAGES.DiscoverPage)
          );
        }
      })
    );

  @Effect()
  signInCompleted = this.actions$
    .pipe(
      ofType(CoreActions.SIGN_IN_COMPLETED),
      map((action: CoreActions.SignInCompleted) => {
        const { type, payload = { user_data: {} } } = action;
        return payload.user_data.site;
      }),
      mergeMap((site) => {
        const returnedActions = [];
        if (this.cordova) {
          returnedActions.push(new CoreActions.SetSiteHeaders(site));
        }
        return returnedActions;
      })
    );

  @Effect({ dispatch: false })
  onboardingCompleted = this.actions$
    .pipe(
      ofType(CoreActions.ONBOARDING_COMPLETED),
      withLatestFrom(this.store.select(CoreStore.getDeeplinkString)),
      map(([action, deeplinkString]) => {
        if (deeplinkString && deeplinkString.length > 0) {
          this.navCtrl.navigateRoot(deeplinkString);
        } else {
          this.navCtrl.navigateRoot(PAGE_TO_URL_TRANSFORMER.getUrlByPageName(APP_PAGES.DiscoverPage));
        }
      })
    );

  constructor(
    private actions$: Actions,
    private storage: Storage,
    private auth: AuthorizationService,
    private funzApi: FunzApiService,
    private store: Store<IAppState>,
    private geolocation: Geolocation,
    private alertCtrl: AlertController,
    private translateService: TranslateService,
    private appApi: AppApi,
    private seoService: SeoService,
    private navCtrl: NavController,
    private router: Router,
    private trackingService: TrackingService,
    private cookieService: CookieService,
    private paramsService: ParamsService,
    private ghs: GeneralHelperService,
    private errorTracker: ErrorTrackerService,
    private pwaApiService: PwaApiService,
  ) {}

   setStorage(data: AuthHeaders | null) {
    const promises = [];
    IonicStorageKeyArray.forEach((key: string) =>
       promises.push(this.storage.set(key, getDataValue(key, data)))
    );
    return Promise.all(promises);
  }

  getAuthConfirmTexts() {
    return this.translateService
      .get([
        'store.core.auth_confirm.title',
        'store.core.auth_confirm.subtitle',
        'store.core.auth_confirm.ok',
        'store.core.auth_confirm.cancel'
      ]).pipe(
      take(1));
  }

  getErrorTexts() {
    return this.translateService
      .get(['error.title', 'error.message', 'error.button']).pipe(
      take(1));
  }

  goToDeeplinkedPage(page: APP_PAGES, params = {}) {
    this.store.dispatch(new CoreStore.SetRequestedPageParams(params));
    this.store.dispatch(new CoreStore.SetDeepLinkedPage(page));
  }

  getQueryVariable(url, variable) {
    const urlQuery = url.split('?').pop();
    const params = new URLSearchParams(urlQuery);
    return params.get(variable);
  }

  appsFlyerDeepLinkHandler(deeplinkString) {
    const link = deeplinkString.split('funzing://app/')[1] || 'home';
    this.deeplinkHandler(link);
  }

  deeplinkHandler(link) {
    this.store.dispatch(new CoreActions.SetDeeplinkString(link));
    this.navCtrl.navigateRoot(link);
  }

  clearAuthHeadersFromStorage() {
    return IonicStorageKeyArray.map((key: string) =>
      this.storage.set(key, null).catch((error: Error) => this.ghs.logStorageError(error))
    );
  }
}
