import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CategoryBoxComponent } from './category-box/category-box';
import { CategoryScrollComponent } from './category-scroll/category-scroll';
import { FunzScrollComponent } from './funz-scroll/funz-scroll';
import { FunzSlidesComponent } from './funz-slides/funz-slides';
import { FunzStarsComponent } from './funz-stars/funz-stars';
import { PipesModule } from '../pipes/pipes.module';
import { OwnerNameComponent } from './owner-name/owner-name';
import { FunzTabsComponent } from './funz-tabs';
import { EventsScrollComponent } from './events-scroll/events-scroll';
import { EventBoxComponent } from './event-box/event-box';
import { LocationMapComponent } from './location-map/location-map';
import { LoyaltyCardComponent } from './loyalty-card';
import { LoyaltyPointComponent } from './loyalty-point/loyalty-point';
import { RequireAuthDirective } from './require-auth/require-auth.directive';
import { OrderComponents } from './order';
import { ContactMeDirective } from './contact-me/contact-me.directive';
import { FollowDirective } from './follow/follow.directive';
import { FunzFiltersComponent } from './funz-filters/funz-filters';
import { ShareWithFriendsComponent } from './share-with-friends/share-with-friends';
import { ShareDirective } from './share/share.directive';
import { LoyaltyCardWideComponent } from './loyalty-card-wide/loyalty-card-wide';
import { NoConnectionComponent } from './no-connection/no-connection';
import { TicketNotificationComponent } from './ticket-notification/ticket-notification';
import { TicketsBarcodeComponent } from './tickets-barcode/tickets-barcode';
import { OrderSubscriptionComponent } from './order-subscription/order-subscription';
import { SubscriptionBannerComponent } from './subscription-banner/subscription-banner';
import { OrderCountdownTimerComponent } from './order-countdown-timer/order-countdown-timer';
import { FacebookProvider } from '../shared-stores/auth/providers/facebook';
import { ShareModalViewComponent } from './share/share-modal-view/share-modal-view.component';
import { FunzBoxComponent } from './funz-box/funz-box';
import { StickyLoaderComponent } from './sticky-loader/sticky-loader';
import { RequireAuthModalComponent } from './require-auth/require-auth';
import { OrderPaymentErrorComponent } from './order-payment-error/order-payment-error';
import { GiftCardModalComponent } from './gift-card-modal/gift-card-modal';
import { ReactiveFormsModule } from '@angular/forms';
import { MobileFooterMenuComponent } from './mobile-footer-menu/mobile-footer-menu';
import { RouterModule } from '@angular/router';
import { AuthComponentsModule } from '../auth-components/auth-components.module';
import { ContactUsModalComponent } from './contact-us-modal/contact-us.modal';
import { ContactSentModalComponent } from './contact-sent-modal/contact-sent';
import { FunzMenuModalService } from './funz-menu/funz-menu-modal.service';
import { FunzVideoComponent } from './funz-video/funz-video.component';
import { SubscriptionInfoModalComponent } from './subscription-info/subscription-info';
import { FunzAdminStatsComponent } from './funz-admin-stats/funz-admin-stats';
import { FunzAdminParticipantsStatsComponent } from './funz-admin-participants-stats/funz-admin-participants-stats';
import { ChatModalComponent } from './chat-modal/chat.modal';
import { ChatComponent } from './chat/chat';
import { DirectivesModule } from '../directives/directives.module';
import { TicketOrderNotificationComponent } from './ticket-order-notification/ticket-order-notification.component';
import { GeneralAlertComponent } from './general-alert/general-alert';
import { ShareViewComponent } from './share/share-view/share-view.component';
import { FunzVirtualScrollComponent } from './funz-virtual-scroll/funz-virtula-scroll';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { OrderEventAvailabilityComponent } from './order-event-availability/order-event-availability';
import { OrderEventOrderableComponent } from './order-event-orderable/order-event-orderable';
import { AppHeaderComponent } from './app-header/app-header';
import { ImageSliderModalComponent } from './image-slider/image-slider-modal.component';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { OrderPaymentWaitComponent } from './order-payment-wait/order-payment-wait';

@NgModule({
  declarations: [
    CategoryBoxComponent,
    CategoryScrollComponent,
    FunzScrollComponent,
    FunzSlidesComponent,
    FunzStarsComponent,
    OwnerNameComponent,
    EventsScrollComponent,
    EventBoxComponent,
    LocationMapComponent,
    FunzTabsComponent,
    RequireAuthDirective,
    ShareDirective,
    ...OrderComponents,
    ContactMeDirective,
    LoyaltyCardComponent,
    LoyaltyPointComponent,
    FollowDirective,
    FunzFiltersComponent,
    ShareWithFriendsComponent,
    LoyaltyCardWideComponent,
    NoConnectionComponent,
    TicketNotificationComponent,
    TicketsBarcodeComponent,
    OrderSubscriptionComponent,
    SubscriptionBannerComponent,
    StickyLoaderComponent,
    OrderCountdownTimerComponent,
    ShareModalViewComponent,
    ShareViewComponent,
    FunzBoxComponent,
    OrderPaymentErrorComponent,
    GiftCardModalComponent,
    MobileFooterMenuComponent,
    ContactUsModalComponent,
    ContactSentModalComponent,
    FunzVideoComponent,
    FunzAdminStatsComponent,
    FunzAdminParticipantsStatsComponent,
    SubscriptionInfoModalComponent,
    ChatComponent,
    ChatModalComponent,
    TicketOrderNotificationComponent,
    GeneralAlertComponent,
    FunzVirtualScrollComponent,
    OrderEventAvailabilityComponent,
    OrderEventOrderableComponent,
    AppHeaderComponent,
    ImageSliderModalComponent,
    ImageGalleryComponent,
    OrderPaymentWaitComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    TranslateModule.forChild(),
    PipesModule,
    ReactiveFormsModule,
    AuthComponentsModule,
    DirectivesModule,
    VirtualScrollerModule,
  ],
  exports: [
    IonicModule,
    PipesModule,
    CategoryBoxComponent,
    CategoryScrollComponent,
    FunzSlidesComponent,
    FunzStarsComponent,
    FunzScrollComponent,
    ShareDirective,
    OwnerNameComponent,
    FunzTabsComponent,
    EventsScrollComponent,
    EventBoxComponent,
    LocationMapComponent,
    RequireAuthDirective,
    LoyaltyCardComponent,
    LoyaltyPointComponent,
    ...OrderComponents,
    ContactMeDirective,
    FollowDirective,
    ShareWithFriendsComponent,
    LoyaltyCardWideComponent,
    FunzFiltersComponent,
    ShareWithFriendsComponent,
    NoConnectionComponent,
    TicketNotificationComponent,
    TicketsBarcodeComponent,
    OrderSubscriptionComponent,
    SubscriptionBannerComponent,
    StickyLoaderComponent,
    OrderCountdownTimerComponent,
    ShareModalViewComponent,
    ShareViewComponent,
    FunzBoxComponent,
    OrderPaymentErrorComponent,
    GiftCardModalComponent,
    MobileFooterMenuComponent,
    ContactUsModalComponent,
    FunzAdminStatsComponent,
    FunzAdminParticipantsStatsComponent,
    FunzVideoComponent,
    ChatComponent,
    ChatModalComponent,
    TicketOrderNotificationComponent,
    GeneralAlertComponent,
    FunzVirtualScrollComponent,
    OrderEventAvailabilityComponent,
    OrderEventOrderableComponent,
    OrderPaymentWaitComponent,
    AppHeaderComponent
  ],
  providers: [FacebookProvider, FunzMenuModalService],
  entryComponents: [
    ShareModalViewComponent,
    ShareViewComponent,
    OrderPaymentErrorComponent,
    GiftCardModalComponent,
    ContactUsModalComponent,
    ContactSentModalComponent,
    FunzVideoComponent,
    SubscriptionInfoModalComponent,
    ChatModalComponent,
    OrderEventAvailabilityComponent,
    OrderEventOrderableComponent,
    ImageGalleryComponent,
    OrderPaymentWaitComponent
  ]
})
export class ComponentsModule {}
