import { Component, isDevMode } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { AppApi } from './core/api/app.api';
import { AppServices } from './core/api/app.services';
import { DocumentService, ReadingDirection } from './core/services/document.service';
import { ENV } from '../environments/environment';
import * as LogRocket from 'logrocket';
import { Device } from '@ionic-native/device/ngx';
import { get } from 'lodash';
import { AndroidBackBtnService } from './core/services/android-back-btn.service';
import { StatusBarService } from './core/services/status-bar.service';
import { TrackingService } from './core/services/tracking.service';
import { IAppState } from './core/store/app.reducers';
import { Store } from '@ngrx/store';
import { InitFilter } from './shared-stores/search';
// import * as Sentry from 'sentry-cordova';
import { PAGE_TO_URL_TRANSFORMER } from './app-routing.utils';
import { APP_PAGES } from './pages';
import { Storage } from '@ionic/storage';
import { ErrorTrackerService } from './core/services/error-tracker.service';
import VersionJson from '../assets/api/version.json';
import { RoutingStateService } from './core/services/routing-state.service';
import { PwaApiService } from './core/services/pwa-api.service';
import { GeneralPwaService } from './core/services/general-pwa.service';
import * as fromCities from './core/store/cities';
import { OneSignalService } from './core/services/onesignal.service';
import { take } from 'rxjs/operators';
import * as fromCore from './core/store/core';

const versionNumber = VersionJson.version;
const baseHref = `https://s3.eu-west-2.amazonaws.com/mobile-assets-uk/${versionNumber}/`;
const releaseName = (ENV.mode === 'Prod') ? versionNumber : versionNumber + '_' + ENV.mode;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  windowObj = window as any;
  siteheaders = this.appApi.siteheaders$;
  currentSiteId: number;
  siteCurrencyCode: string;
  showMenu$ = this.generalPwaService.showMenu;
  cordova = !!this.windowObj.cordova;
  showPromoBanner = false;

  constructor(
    private platform: Platform,
    private pwaApiService: PwaApiService,
    private generalPwaService: GeneralPwaService,
    private screenOrientation: ScreenOrientation,
    private appApi: AppApi,
    private appServices: AppServices,
    private documentService: DocumentService,
    private device: Device,
    private androidBackBtnService: AndroidBackBtnService,
    private statusBarService: StatusBarService,
    private store: Store<IAppState>,
    private trackingService: TrackingService,
    private navCtrl: NavController,
    private storage: Storage,
    private errorTracker: ErrorTrackerService,
    private routingState: RoutingStateService,
    private oneSignalService: OneSignalService
  ) {
    this.trackingService.appVersion = versionNumber;
    if (!this.cordova) {
      this.appApi.initializePWA();
    }
    this.initializeApp();
    this.storage.ready().then((s) => {
      const currentDriver = s.driver();
      LogRocket.log(`Ionic Storage Ready, using driver: ${currentDriver}`);
      LogRocket.log('Storage Config:', JSON.stringify(get(s, '_config'), null, 2));
    }).catch((error: Error) => {
      this.errorTracker.trackError(error);
    });
  }

  onAppOpenAttributionSuccess(res: any) {
    const resJson = JSON.parse(res);
    const data = get(resJson, 'data');
    if (get(resJson, 'status') === 'failure') {
      this.trackingService.traceAction('deeplink_on_app_attribution_failure', resJson);
      LogRocket.log('deeplink on onAppOpenAttributionSuccess failure', resJson);
    } else {
      this.appApi.appsFlyerURLHandler(data);
    }
  }

  onSuccess(result: any) {
    this.appApi.appsFlyerSuccess(result);
  }

  onError(error) {
    this.errorTracker.trackError(error);
  }

  initializeApp() {
    if (this.windowObj.cordova) {
      LogRocket.init('iljs8t/app-2', {
        dom: {
          baseHref
        },
        release: releaseName,
        network: {
          requestSanitizer: (request: any) => {
            // if the url contains 'ignore'
            if (request.url.toLowerCase().indexOf('auth') !== -1) {
              // scrub out the body
              request.body = null;
            }
            return request;
          }
        }
      });
      LogRocket.getSessionURL(sessionURL => {
        // Sentry.configureScope(scope => {
        //   scope.setTag('logRocketSessionUrl', sessionURL);
        //   scope.setExtra('logRocketSessionUrl', sessionURL);
        // });
      });
      LogRocket.log('Finished Logrocket init');
    }

    this.storage.get('isOnline').then(res => {
      const online = res === 1 || res === 0 ? res : null;
      this.store.dispatch(new fromCities.SetOnline(online));
    })

    this.appApi.checkFirstTime();
    this.appApi.fetchHeaders();
    this.siteheaders.subscribe(siteheaders => {
      if (this.windowObj.cordova) {
        LogRocket.log('loaded siteHeaders', siteheaders);
      }
      // log got siteheaders
      // this language will be used as  fallback when a translation isn't found in the current language
      this.appServices.useTranslateAsDefault(siteheaders.locales);

      this.documentService.setReadingDirection(siteheaders.direction as ReadingDirection);
      if (siteheaders.direction === 'ltr') {
        this.documentService.setVariable('--ion-font-family', '\'Montserrat\', sans-serif');
      } else if (siteheaders.direction === 'rtl') {
        this.documentService.setVariable('--ion-font-family', '\'Assistant\', sans-serif');
      }
      this.documentService.setVariable('font-family', 'var(--ion-font-family) !important');
      if (this.currentSiteId && this.currentSiteId !== siteheaders.site_id) {
        this.appApi.resetApp();
      }
      this.currentSiteId = siteheaders.site_id;
      this.siteCurrencyCode = siteheaders.currency_code;
      // if (!this.windowObj.cordova && this.currentSiteId === 2) {
      //   this.promoBanner();
      // }
    });
    this.platform.ready().then(() => {
      this.routingState.loadRouting();
      LogRocket.log('Platform ready');
      this.appApi.loadMarketingParams();
      if (this.windowObj.cordova) {
        this.oneSignalService.loadOneSignal(true);
        this.statusBarService.init();
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
        if (this.platform.is('android')) {
          this.androidBackBtnService.init();
          if (this.windowObj.AndroidNotch) {
            const style = document.documentElement.style;
            this.windowObj.AndroidNotch.getInsetTop(px => {
              style.setProperty('--safe-area-inset-top', px ? px + 'px' : '25px');
            }, (err) => console.error('Failed to get insets top:', err));
          }
        }
        if (this.device.model.indexOf('iPhone') !== -1) {
          if ((this.device.model.indexOf('10,6') !== -1)
            || (this.device.model.indexOf('10,7') !== -1)
            || (this.device.model.indexOf('11') !== -1)
            || (this.device.model.indexOf('12') !== -1)
            || (this.device.model.indexOf('13') !== -1)) {
            this.documentService.addClass('rounded-iphone');
          }
        }
        LogRocket.log('Appsflyer start init');
        if (ENV.mode === 'Prod' || ENV.mode === 'XYZ' || ENV.mode === 'SPACE') {
          const appsflyerOptions = {
            devKey: 'fWswdEL9vmDjz3NtJQdUh9',
            appId: '1053086694',
            onInstallConversionDataListener: true,
            isDebug: false
          };
          this.windowObj.plugins.appsFlyer.registerOnAppOpenAttribution(
            this.onAppOpenAttributionSuccess.bind(this),
            this.onError.bind(this)
          );
          this.windowObj.plugins.appsFlyer.initSdk(
            appsflyerOptions,
            this.onSuccess.bind(this),
            this.onError.bind(this)
          );
          this.windowObj.plugins.appsFlyer.setCurrencyCode(this.siteCurrencyCode);
          this.windowObj.plugins.appsFlyer.enableUninstallTracking('355852510617');
          this.windowObj.plugins.appsFlyer.trackEvent('af_app_opened');
        }
        LogRocket.log('Appsflyer end init, beginning Sentry init');

        // keep loading sentry here
        // Sentry.init({
        //   dsn: 'https://eed2f17c5d164603aae3ad0e02db1176@sentry.io/1872462',
        //   release: `app-20@${releaseName}`
        // });
        // Sentry.configureScope(scope => {
        //   scope.setTag('environment', ENV.mode);
        // });
        LogRocket.log('finished Sentry init');
        const starterEl = document.getElementById('splashscreen');
        if (starterEl) {
          starterEl.setAttribute('class', 'hiding');
          setTimeout(() => starterEl.parentNode.removeChild(starterEl), 300);
        }
      } else {
        setTimeout(() => {
          const starterEl = document.getElementById('splashscreen');
          if (starterEl) {
            starterEl.setAttribute('class', 'hiding');
            setTimeout(() => starterEl.parentNode.removeChild(starterEl), 300);
          }
        }, 3000);
        this.pwaApiService.getVersionNum().then((response) => {
          const version = response.version;
          (window as any).pwa_version = version;
          this.refreshOnVersionChange();
          if (!isDevMode()) {
            const domain = location.hostname.replace('m.', '');
            const subdomain = domain.split('.')[0];
            LogRocket.init(subdomain === 'uk' ? 'funzing/funzing-uk-pwa' : 'qurgwq/funzing-pwa', {
              release: version,
              console: {
                isEnabled: {
                  error: false
                }
              },
              network: {
                requestSanitizer: (request: any) => {
                  if (request.url.toLowerCase().indexOf('auth') !== -1) {
                    request.body = null;
                  }
                  return request;
                }
              }
            });
          }
        }).catch((err) => { })
      }
    });
    this.platform.pause.subscribe(() => {
      LogRocket.log('App lost focus');
      this.appApi.setLocalStorageExpiration('funz_pause_app', 1000 * 60 * 30);
    });
    this.platform.resume.subscribe(() => {
      LogRocket.log('App returned focus');
      this.store.dispatch(new InitFilter());
      this.appApi.isLocalStorageExpired('funz_pause_app').then((expired) => {
        LogRocket.log(`funz_pause_app status: ${expired}`);
        if (expired) {
          this.navCtrl.navigateRoot(PAGE_TO_URL_TRANSFORMER.getUrlByPageName(APP_PAGES.DiscoverPage));
        }
      });
    });
  }

  refreshOnVersionChange() {
    const wind = window as any;
    wind.onblur = () => {
      wind.blurred = true;
    }
    wind.onfocus = () => {
      if (!wind.blurred) {
        console.log('not blurred before, returning');
        return;
      }
      const currentVersion = wind.pwa_version;
      console.log('Current version: ' + currentVersion);
      this.pwaApiService.getVersionNum().then((versionObj) => {
        console.log('Latest version: ' + versionObj.version)
        if (versionObj.version !== currentVersion) {
          location.reload();
        }
      });
    }
  }

  closeMenu() {
    this.generalPwaService.showMenu.next(false);
  }

  // PROMO banner
  promoBanner() {
    const promoBanner = sessionStorage.getItem('promo-banner');
    if (!promoBanner) {
      setTimeout(() => this.showPromoBanner = true, 5000);
    }
  }

  closeBanner() {
    this.showPromoBanner = false;
    sessionStorage.setItem('promo-banner', 'true');
  }

  goGiftcard() {
    this.closeBanner();
    this.store.select(fromCore.getRootDomain).pipe(take(1)).subscribe(rootDomain => {
      window.open(`${rootDomain}/gift_card?utm_source=funzingpwa&utm_campaign=blackfriday2021&utm_medium=organic`, '_blank');
    });
  }

}
