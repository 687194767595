import { Component, EventEmitter, Output, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take, skip } from 'rxjs/operators';
import * as fromApp from '../../core/store/app.reducers';
import * as fromAuth from '../../shared-stores/auth/store';
import { TrackingService } from '../../core/services/tracking.service';
import { OpenPasswordReset, OpenSignUpPage } from '../../shared-stores/auth/store/auth.actions';
import { Observable, Subscription } from 'rxjs';
import { getSubmitState } from '../../shared-stores/auth/store/auth.selectors';
import { FacebookProvider } from '../../shared-stores/auth/providers/facebook';
import { isEmpty, keys } from 'lodash';
import { WindowResizeService } from 'src/app/core/services/window-resize.service';
import { ResetAction, SetUserDefinedPropertyAction, SetValueAction } from 'ngrx-forms';
import { LOGIN_FORM_ID, LOGIN_FORM_INITIAL_STATE_VALUE } from '../../shared-stores/auth/store/login/login.reducers';
import {
  SignInWithApple, AppleSignInResponse, AppleSignInErrorResponse, ASAuthorizationAppleIDRequest
} from '@ionic-native/sign-in-with-apple/ngx';
import { Platform } from '@ionic/angular';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';

@Component({
  selector: 'login-email',
  templateUrl: 'login-email.html',
  styleUrls: ['login-email.scss']
})
export class LoginEmailComponent implements OnInit, OnDestroy {
  @Output() login = new EventEmitter();

  showErrors = false;
  formState: Observable<any>;
  objectKeys = keys;
  isDisableSubmit$ = this.store.select(getSubmitState);
  fromMenu$: Observable<any> = this.store.select(fromAuth.getAuthFromMenu);
  facebookSignError$: Observable<boolean> = this.store.select(fromAuth.getFacebookSignError);
  appleSignError$: Observable<boolean> = this.store.select(fromAuth.getAppleSignError);
  cordova = !!(window as any).cordova;
  isIos = this.platform.is('ios');
  private resizeSubscription: Subscription;

  constructor(
    private platform: Platform,
    private store: Store<fromApp.IAppState>,
    private facebookProvider: FacebookProvider,
    private trackingService: TrackingService,
    private windoResizeService: WindowResizeService,
    private signInWithApple: SignInWithApple,
    private googlePlus: GooglePlus,
    private socialAuthService: SocialAuthService
  ) {
    this.windoResizeService.init();
    this.formState = this.store.select('auth').pipe(select('loginForm'));
    if (!this.cordova) {
      this.facebookProvider.initialize();
    }
  }

  ngOnInit(): void {
    this.resetForm();
  }

  onSubmit() {
    this.store.dispatch(new fromAuth.ResetFbError());
    this.formState.pipe(take(1)).subscribe(resolvedFormState => {
      this.showErrors = true;
      if (isEmpty(resolvedFormState.errors)) {
        this.trackingService.traceAction('email_login_click', { type: 'Login' });
        this.login.emit();
      }
    });
  }

  goToEmailSignUp() {
    this.store.dispatch(new OpenSignUpPage());
  }

  goToPasswordReset() {
    this.store.dispatch(new OpenPasswordReset());
  }

  onFocus(e) {
    this.resizeSubscription = this.windoResizeService.resizeInProgress.pipe(skip(1))
      .subscribe(resizeInProgress => {
        if (!resizeInProgress) {
          e.srcElement.scrollIntoView({ block: 'start', inline: 'center', behavior: 'smooth' });
          this.onBlur();
        }
      });
  }

  onBlur() {
    if (this.resizeSubscription) { this.resizeSubscription.unsubscribe(); }
  }

  doAppleSignUp() {
    this.store.dispatch(new fromAuth.ShowLoader());
    this.signInWithApple.signin({
      requestedScopes: [
        ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
        ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
      ]
    }).then((res: AppleSignInResponse) => {
      this.store.dispatch(new fromAuth.AppleSignIn(res));
    }).catch((error: AppleSignInErrorResponse) => {
      this.store.dispatch(new fromAuth.AppleSignInError(error));
    });
  }

  doFacebookSignUp() {
    this.showErrors = false;
    this.trackingService.traceAction('facebook_login_click', { type: 'Login' });
    this.store.dispatch(new fromAuth.ShowLoader());
    if (this.cordova) {
      this.store.dispatch(new fromAuth.TryFBSignIn());
    } else {
      this.facebookProvider.signIn();
    }
  }

  doGoogleSignUp() {
    this.showErrors = false;
    this.trackingService.traceAction('google_login_click', { type: 'Login' });
    this.store.dispatch(new fromAuth.ShowLoader());
    if (this.cordova) {
      this.googlePlus.login({}).then((res) => {
        this.store.dispatch(new fromAuth.GoogleSignIn({
          id: res.userId,
          email: res.email,
          name: res.displayName,
          authToken: res.accessToken
        }));
      })
      .catch(err => {
        console.log(`Error ${JSON.stringify(err)}`);
        this.store.dispatch(new fromAuth.GoogleSignInError(err))
      });
    } else {
      this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((res) => {
        this.store.dispatch(new fromAuth.GoogleSignIn({
          id: res.id,
          email: res.email,
          name: res.name,
          authToken: res.authToken
        }));
      })
      .catch(err => {
        console.log(`Error ${JSON.stringify(err)}`);
        this.store.dispatch(new fromAuth.GoogleSignInError(err))
      });
    }
  }

  resetForm() {
    this.store.dispatch(new SetUserDefinedPropertyAction(LOGIN_FORM_ID, 'serverError', ''));
    this.store.dispatch(new SetValueAction(LOGIN_FORM_ID, LOGIN_FORM_INITIAL_STATE_VALUE));
    this.store.dispatch(new ResetAction(LOGIN_FORM_ID));
  }

  ngOnDestroy() {
    this.store.dispatch(new fromAuth.ResetFbError());
    this.windoResizeService.destroy();
  }
}
