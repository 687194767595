import { createSelector } from '@ngrx/store';
import { IProfileState } from './profile.reducer';
import { IAppState } from '../app.reducers';
import { getRootDomain, getAuthHeaders, getIsSignedIn } from '../core';
import { AuthHeaders } from '../../models/authHeaders.model';

export const getProfile = (state: IAppState) => state.profile;

export const getSellerAndRootDomain = createSelector(
  getProfile,
  getRootDomain,
  getAuthHeaders,
  (profile: IProfileState, rootDomain: string, authHeaders: AuthHeaders) => {
    return { is_seller: profile.is_seller, is_affiliate: profile.is_affiliate, user_id: profile.user_id, rootDomain, authHeaders };
  }
);
export const getUserId = createSelector(
  getProfile,
  (profile: IProfileState) => {
    return { user_id: profile.user_id };
  }
);
export const getUserAvatar = createSelector(
  getProfile,
  (profile: IProfileState) => profile.avatar_url
);
export const getNeedsToFbOnboard = createSelector(
  getProfile,
  (profile: IProfileState) => profile.needs_to_fb_onboard
);
export const getEmail = createSelector(
  getProfile,
  (profile: IProfileState) => profile.email
);
export const getName = createSelector(
  getProfile,
  (profile: IProfileState) => profile.name
);
export const getPhone = createSelector(
  getProfile,
  (profile: IProfileState) => profile.phone
);

export const getFbOnboardingData = createSelector(
  getProfile,
  getIsSignedIn,
  (profile: IProfileState, signedIn: boolean) => {
    return {
      signedIn,
      userId: profile.user_id,
      name: profile.name,
      email: profile.email,
      notSubscribedToMarketingEmails: false,
      needsToFbOnboard: profile.needs_to_fb_onboard,
    };
  }
);
export const getNameAndAvatar = createSelector(
  getProfile,
  getIsSignedIn,
  getRootDomain,
  (profile: IProfileState, signedIn: boolean, rootDomain: string) => {
    return {
      signedIn,
      name: profile.name,
      avatar_url: profile.avatar_url,
      email: profile.email,
      rootDomain,
      userId: profile.user_id
    };
  });
export const getOneSignalData = createSelector(
  getProfile,
  (profile: IProfileState) => {
    return {
      user_id: profile.user_id,
      onesignal_paying: profile.onesignal_paying,
      top_category: profile.top_category
    };
  }
);
export const getLoyaltyCardMessage = createSelector(
  getProfile,
  (profile: IProfileState) => profile.points_message
);
export const getTotalLoyaltyPoints = createSelector(
  getProfile,
  (profile: IProfileState) => profile.total_loyalty_points
);
export const getCredit = createSelector(
  getProfile,
  (profile: IProfileState) => profile.total_credits
);
export const getUnreadConversationsCount = createSelector(
  getProfile,
  (profile: IProfileState) => profile.unread_conversations_count
);
export const getUnreadNotificationsCount = createSelector(
  getProfile,
  (profile: IProfileState) => profile.unread_notifications_count
);
export const getHaveUnreads = createSelector(
  getUnreadConversationsCount,
  getUnreadNotificationsCount,
  (conversations, notifications) => conversations > 0 || notifications > 0
);
export const getAllUnreads = createSelector(
  getUnreadConversationsCount,
  getUnreadNotificationsCount,
  (conversations, notifications) => conversations + notifications
);

export const getProfileCredits = createSelector(
  getProfile,
  (profile: IProfileState) => profile.credits
);

export const getProfileSubscribedToMarketingEmails = createSelector(
  getProfile,
  (profile: IProfileState) => profile.subscribed_to_marketing_emails
);

export const getProfileLoyaltyPoints = createSelector(
  getProfile,
  (profile: IProfileState) => profile.loyalty_points
);
export const getReferralInvite = createSelector(
  getProfile,
  (profile: IProfileState) => profile.invite
);
export const getReferralInviteReferralCreditsAmount = createSelector(
  getProfile,
  (profile: IProfileState) => profile.invite.referral_credits_amount
);
export const getReferralInviteUrl = createSelector(
  getProfile,
  (profile: IProfileState) => profile.invite.invite_url
);
export const getProfileFormRepsonse = createSelector(
  getProfile,
  (profile: IProfileState) => profile.form
);

export const getProfileHasSubscription = createSelector(
  getProfile,
  (profile: IProfileState) => profile.has_active_subscriptions
);

export const getProfileSubscription = createSelector(
  getProfile,
  (profile: IProfileState) => profile.subscription
);
