import { CategoryBox } from '../../../funz/category.model';
import { IFunzCollection, Funz, IFunzEventSelectedSeats, IEventSelecteSeatsCollection } from '../../../funz/funz.model';
import { IAppState } from '../../../core/store/app.reducers';
import { createSelector } from '@ngrx/store';

import { State } from './funz.reducers';
import { intersectArrays } from './funz.utils';
import { get, keys } from 'lodash';
import { FunzHelperService } from '../../../core/services/funz-helper.service';
import { getCategories } from '../../../core/store/categories';

export const getFunz = (state: IAppState) => state.funz;

export const getFunzEntities = createSelector(
  getFunz,
  (funz: State) => funz.funzes
);

export const getSelectedFunzId = createSelector(
  getFunz,
  (funz: State) => funz.selectedFunzId
);

export const getSelectedFunz = createSelector(
  getFunzEntities, // funz entities,
  getSelectedFunzId,
  (entities, selectedId) => {
    return entities[selectedId];
  }
);

export const getFunzes = createSelector(
  getFunz,
  (funz: State) => funz.funzes
);

export const getFunzById = (funzId) => createSelector(
  getFunzes,
  (funz: any) => keys(funz).map(funzes => funz[funzes]),
  (funzes) => funzes && funzes[funzId] ? funzes[funzId] : null
);

export const getFunzEventList = (funzId) => createSelector(
  getFunzById(funzId),
  (funz: any) => funz && funz.events
);

export const getCurrentFunzTrackingStatus = createSelector(
  getSelectedFunz,
  (funz: Funz) => {
    const funzHelper = new FunzHelperService(funz);
    return funzHelper.trackingEventStatus();
  }
);

export const getFunzTrackingStatus = (funzId) => createSelector(
  getFunzById(funzId),
  (funz: Funz) => {
    const funzHelper = new FunzHelperService(funz);
    return funzHelper.trackingEventStatus();
  }
);

export const getFetchedCategories = createSelector(
  getFunz,
  (funz: State) => funz.fetchedCategories
);

export const getFetchingCategories = createSelector(
  getFunz,
  (funz: State) => funz.fetchingCategories
);

export const getIsCategoryBeingFetched = createSelector(
  getFunz,
  (funz: State) => funz.categoryPageFetchStart
);

export const getFeatured = createSelector(getFunz, (funz: State) => {
  const funzes = funz.funzes;
  return funz.featured.map(funzId => funzes[funzId]);
});

export const getDiscoverLimit = createSelector(
  getFunz,
  (funz: State) => funz.discoverLimit
);

export const getFunzesIdsByCategories = createSelector(
  getFunz,
  (state) => state.funzIdsByCategory
);

export const getDiscoveredWithFunz = createSelector(
  getFunzes,
  getFunzesIdsByCategories,
  getCategories,
  (
    funzes: IFunzCollection,
    funzesIds: {[key: number]: number[]},
    categories: CategoryBox[],
  ) => {
    const result = categories.length
      ? categories
        .map(row => row.id)
        .filter(id => id in funzesIds)
        .map(id => {
          const category = categories.find(c => c.id === Number(id));
          return {
            category,
            funzes: funzesIds[id] ? funzesIds[id].map(passedId => funzes[passedId]) : []
          };
        })
      : [];
    return result;
  }
);

export const getFunzEvent = createSelector(
  getFunz,
  (funz: State) => funz.fetchedCategories
);

export const getOrderForm = createSelector(
  getFunz,
  (funz: State) => funz.funzForm
);

export const getSearchResults = createSelector(
  getFunz,
  (funz: State) => funz.searchResults
);

export const getSearchResultsFunz = createSelector(
  getFunzes,
  getSearchResults,
  (funzes: IFunzCollection, results: number[]) => {
    return results.map((funzId: number) => funzes[funzId]);
  }
);

export const getFilterResults = createSelector(
  getFunz,
  (funz: State) => funz.filterResults
);

export const getFilteredSearchResultsFunz = createSelector(
  getFunzes,
  getSearchResults,
  getFilterResults,
  (funzes: IFunzCollection, queryResults: number[], filteredResults) => {
    const results = intersectArrays(queryResults, filteredResults);
    return results.map((funzId: number) => funzes[funzId]);
  }
);

export const getIsLoadingCategories = createSelector(
  getFetchingCategories,
  (categories: number[]) => categories.length > 0
);

export const getHasFeedbackCount = createSelector(
  getSelectedFunz,
  (selectedFunz: Funz) => {
    return selectedFunz ? selectedFunz.displayedFeedbackCount > 0 : false;
  }
);

export const getHasPastVisitorsCount = createSelector(
  getSelectedFunz,
  (selectedFunz: Funz) => {
    return selectedFunz ? selectedFunz.pastVisitorsCount > 4 : false;
  }
);

export const getWheelchairSeats = createSelector(
  getSelectedFunz,
  (selectedFunz: Funz) => {
    return JSON.parse(get(selectedFunz, 'amounts.wheelchair_seats', '{}'));
  }
);

export const getEventSelecteSeatsEntities = createSelector(
  getFunz,
  (funz: State) => funz.eventSelectedSeats
);

export const getEventFunzId = createSelector(
  getFunz,
  (funz: State) => funz.selectedEventId
);

export const getIsEventWithSeats = createSelector(
  getSelectedFunz,
  getEventFunzId,
  (funz: Funz, eventId: number) => {
    if (funz) {
      const { events = [] } = funz;
      const selectedEvent: any = events.find((event: any) => {
        return event.id === eventId;
      });
      return selectedEvent ? selectedEvent.with_seating : false;
    } else {
      return false;
    }
  }
);

export const getEventSelectedSeats = createSelector(
  getEventSelecteSeatsEntities,
  getEventFunzId,
  (selectedSeats: IEventSelecteSeatsCollection, eventId: number) => {
    return selectedSeats[eventId];
  }
);

export const getEventSelectedSeatsDate = createSelector(
  getEventSelectedSeats,
  (eventSelectSeats: IFunzEventSelectedSeats) => {
    return eventSelectSeats ? eventSelectSeats.created : undefined;
  }
);

export const getEventSelectedSeatsToken = createSelector(
  getEventSelectedSeats,
  (selectedSeats: IFunzEventSelectedSeats) => selectedSeats.hold_token
);

export const getEventSelectedSeatsCount = createSelector(
  getEventSelectedSeats,
  (selectedSeats: IFunzEventSelectedSeats) => selectedSeats ? selectedSeats.selected_seats.length : 0
);

export const getEventSelectedSeatsByCategory = createSelector(
  getEventSelectedSeats,
  (selectedSeats: any = {}) => {
    const { categories = {} } = selectedSeats;
    const selectedSeatsByCategory = keys(categories).map((categoryKey) => {
      const category = categories[categoryKey];
      return {
        ...category,
        tickets: keys(category.tickets)
      };
    });
    return selectedSeatsByCategory;
  }
);

export const getEventSelectedSeatsByRow = createSelector(
  getEventSelectedSeats,
  (selectedSeats: any = {}) => {
    const { categories = {} } = selectedSeats;
    let selectedSeatsByRows = {};
    keys(categories).forEach((categoryId) => {
      const { tickets = {} } = categories[categoryId];
      keys(tickets).forEach(selectedSeat => {
        const selectedSeatsItems = selectedSeat.split('-');
        const { [selectedSeatsItems.length - 2]: row, [selectedSeatsItems.length - 1]: seat } = selectedSeatsItems;
        selectedSeatsByRows = {
          ...selectedSeatsByRows,
          [row]: {
            ...selectedSeatsByRows[row],
            [seat]: seat
          }
        };
      });
    });
    return selectedSeatsByRows;
  }
);

export const getEventSelectedSeatsByType = createSelector(
  getEventSelectedSeats,
  getIsEventWithSeats,
  (selectedSeats: any = {}, isEventWithSeats: boolean) => {
    if (!isEventWithSeats) { return; }

    const { categories = {} } = selectedSeats;
    const initalState = { kid: 0, adult: 0 };

    const selectedSeatsByType = initalState;

    keys(categories).forEach((categoryKey) => {
      const category = categories[categoryKey];
      const ticketsKeys = keys(category.tickets);
      ticketsKeys.forEach(ticketKey => {
        const ticketType = category.tickets[ticketKey];
        selectedSeatsByType[ticketType] = selectedSeatsByType[ticketType] ? ++selectedSeatsByType[ticketType] : 1;
      });
    });

    return selectedSeatsByType;
  }
);

export const getIsShowCountdownTimer = createSelector(
  getFunz,
  (funz: State) => {
    return funz.showCountdownTimer;
  }
);

export const getIsStartCountdownTimer = createSelector(
  getFunz,
  (funz: State) => {
    return funz.startCountdownTimer;
  }
);

export const getPaymentWindowClosed = createSelector(
  getFunz,
  (funz: State) => funz.paymentWindowClosed
);
