import { Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { ENV } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { isEmpty } from 'lodash';
import { map, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IAppState } from '../store/app.reducers';
import * as fromCore from './../store/core';

@Injectable()
export class FunzOrderService {
  baseDomain: string;
  cordova = !!(window as any).cordova;

  constructor(private httpClient: HttpClient, private store: Store<IAppState>) {
    this.store
      .select(fromCore.getBaseDomain)
      .subscribe(baseDomain => {
        this.baseDomain = baseDomain;
      });
  }

  eventCalculatePrice(body) {
    const URL = `${this.baseDomain}/price_calculator`;

    const order = {
      funz_id: body.funzId,
      event_id: body.date,
      adults_quantity: body.adult,
      kids_quantity: body.kid,
      coupon_code: body.coupon,
      subscription_selected: String(body.subscription_selected),
      // tickets: body.tickets
    };

    if (body.tickets && body.tickets.length) {
      order['tickets'] = body.tickets;
    }

    return this.httpClient
      .post<any>(URL, order, {
        observe: 'body',
        responseType: 'json'
      })
      .pipe(
        map(response => {
          response.amounts.funzId = body.funz_id;
          return response.amounts;
        })
      );
  }

  funzOrder(body, marketing = {}) {
    const URL = `${this.baseDomain}/my/orders`;

    const order = {
      event_id: body.date,
      adults_quantity: body.adult,
      kids_quantity: body.kid,
      coupon_code: body.coupon,
      subscription_selected: String(body.subscription_selected),
      subscription_price: body.subscription_price,
      plan_system_name: body.plan_system_name,
      ticket_options: body.tickets,
      venue: {
        hold_token: body.hold_token,
        categories: body.selected_seats_by_category
      },
      source: this.cordova ? 'api' : 'web-mobile',
      ...(!isEmpty(marketing) && { marketing })
    };

    return this.httpClient.post<any>(URL, { order }, {
      observe: 'body',
      responseType: 'json',
      withCredentials: true,
      headers: new HttpHeaders ({ 'Content-Type': 'application/json' })
    });
  }
}
